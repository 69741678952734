<template>
  <!-- <transition name="fade" mode="out-in"> -->
    <div class="screen-wrap">
      <v-app class="hmc-app">
        <v-main class="main-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <v-container class="app-body">
            <div class="pe-2 ps-3 app-title text-h5">
              {{$t("title.wardrobe")}}
              <v-spacer></v-spacer>
              <CloseBtn size="40" :callback="exit" />
            </div>
            <div>
              <div class="shop-panel">
                <v-img
                  :src="require('@/assets/explore/menu/shop.png')"
                  max-width="80"
                  class="ms-2 d-flex justify-center align-end text-center text-stroke-md"
                  style="cursor: pointer;"
                  @click="shop()"
                >
                {{$t("title.shop")}}
                </v-img>
              </div>
              <v-img
                class="d-flex align-center px-5 mx-auto"
                :src="require('@/assets/explore/wardrobe.png')"
                contain
              >
                <AbstractAvatar
                  :avatar="studentAvatar"
                  :height="200"
                  :width="200"
                  class="mx-5"
                  :selectedBack="selectedBack"
                  :selectedRight="selectedRight"
                  :selectedHat="selectedHat"
                  :selectedLeft="selectedLeft"
                  :isHouse="true"
                ></AbstractAvatar>
              </v-img>
              <div style="height: 50px; margin-top: -50px; margin-bottom: 20px">
                <div class="pa-2 text-center">
                  <v-btn
                    :disabled="!isChanged"
                    @click="saveAvatar()"
                    large
                    color="yellow"
                    class="text-h5 px-8"
                  >
                    {{ $t("string.wear") }}
                  </v-btn>
                </div>
              </div>
              <div class="items-panel pa-3">
                <div
                  class="d-flex justify-space-between pa-3"
                >
                  <div>
                    <v-btn
                      icon
                      @click="previousPage()"
                      :disabled="currentPage == 1"
                    >
                      <v-img
                        :class="currentPage == 1 ? 'disabled-item-image' : null"
                        contain
                        max-width="40"
                        class="mx-auto"
                        :src="require('@/assets/island/ArrowButtonPre.png')"
                      ></v-img>
                    </v-btn>
                  </div>
                  <!-- <div>{{ currentPage }}/{{ totalPage }}</div> -->
                  <div class="text-stroke-md text-h5">
                    {{$t("string.page")}} {{ currentPage }} / {{ totalPage }}
                  </div>
                  <div>
                    <v-btn
                      icon
                      @click="nextPage()"
                      :disabled="totalPage <= 1 || currentPage == totalPage"
                    >
                      <v-img
                        :class="
                          totalPage <= 1 || currentPage == totalPage
                            ? 'disabled-item-image'
                            : null
                        "
                        contain
                        max-width="40"
                        class="mx-auto"
                        :src="require('@/assets/island/ArrowButtonNex.png')"
                      ></v-img>
                    </v-btn>
                  </div>
                </div>
                <div>
                  <div>
                    <v-row class="mx-3">
                      <v-col
                        class="px-1 py-3"
                        cols="3"
                        @click="
                          avatar.charKey == 'mochi_v3' &&
                          item.Item.category != 'hat'
                            ? null
                            : selectItem(item)
                        "
                        v-for="(item, i) in itemData.slice(a, b)"
                        :key="i"
                      >
                        <Item
                          :imageUrl="JSON.parse(item.Item.images).icon.url"
                          :isSelected="checkSelected(item)"
                          :quantity="item.Inventory.quantity"
                          :isShowQuantity="true"
                        />
                        <div class="text-center mt-2" v-if="settings.debug" ><v-btn small color="primary" @click="sell(item)" :disabled="studentAvatar.includes(item.Item.key)">Sell</v-btn></div>
                      </v-col>
                      <template v-if="itemData.slice(a, b).length != 8">
                        <v-col
                          class="px-1 py-3"
                          cols="3"
                          v-for="j in 8 - itemData.slice(a, b).length"
                          :key="j + 'e'"
                        >
                          <Item
                            v-if="itemData.slice(a, b).length != 8"
                            :isDisabled="true"
                            :isShowQuantity="true"
                          />
                        </v-col>
                      </template>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>

            <v-card class="save-confirm" v-if="buyConfirmDialog">
              <div class="burst"></div>
              <div style="max-width: 480px; height: 100vh; margin: auto">
                <div
                  class="d-flex flex-column align-center justify-center"
                  style="min-height: calc(100vh - 100px) !important"
                >
                  <AbstractAvatar
                    :avatar="JSON.stringify(this.avatar)"
                    :height="300"
                    :width="300"
                    class="mx-5"
                  />
                  <div
                    style="z-index: 1 !important"
                    class="d-flex flex-column align-center justify-center"
                  >
                    <v-btn
                      large
                      color="yellow"
                      class="my-3 mx-3 text-h6 px-10"
                      min-width="100"
                      @click="confirmSave()"
                      :loading="saveApi.isLoading"
                    >
                      {{ $t("action.done") }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </v-container>
        </v-main>
      </v-app>
    </div>
  <!-- </transition> -->
</template>
  <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    Item: () =>
      import(
        /* webpackChunkName: "component-shop-item" */ "@/components/shop/Item.vue"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    isClickSell:false,
    currentPage: 1,
    totalPage: 0,
    isChanged: false,
    a: 0,
    b: 8,
    selectedItems: [],
    selectedHat: null,
    selectedLeft: null,
    selectedRight: null,
    selectedBack: null,
    selectedItemInventory: {},
    buyConfirmDialog: false,
    studentAvatar: {},
    items: [],
    show: true,
    avatar: {},
    itemData: [],
    enterHouse: true,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    saveApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    sellApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    // this.items = [
    //   {
    //     key: "Wardrobe",
    //     name: this.$t("view.PageStudentWardrobe.title"),
    //     route: { name: "PageHouseWardrobe" },
    //     icon: "mdi-tshirt-crew",
    //     color: "red",
    //   },
    //   {
    //     key: "Medal",
    //     name: this.$t("view.PageStudentMedal.title"),
    //     route: { name: "PageHouseMedals" },
    //     icon: "mdi-medal",
    //     color: "amber",
    //   },
    // ];
    this.avatar = this.$_.cloneDeep(JSON.parse(this.auth.Player.avatar));
    this.studentAvatar = this.$_.cloneDeep(this.auth.Player.avatar);
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/wardrobe/visit";
    this.saveApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/avatar/save";
       this.sellApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/item/sell";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.saveApi.callbackReset = () => {
      this.saveApi.isLoading = true;
      this.saveApi.isError = false;
      this.saveApi.error = null;
    };
     this.sellApi.callbackReset = () => {
      this.sellApi.isLoading = true;
      this.sellApi.isError = false;
      this.sellApi.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.saveApi.callbackError = (e) => {
      this.saveApi.isLoading = false;
      this.saveApi.isError = true;
      this.saveApi.error = e;
    };
    this.sellApi.callbackError = (e) => {
      this.sellApi.isLoading = false;
      this.sellApi.isError = true;
      this.sellApi.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.authData = this.$_.clone(this.auth);
      this.itemData = resp.Apparel;
      this.getAvatar();
      this.totalPage = Math.ceil(this.itemData.length / 8);
    };
    this.saveApi.callbackSuccess = (resp) => {
      this.authData.Player = resp.Player;
      this.$store.commit("updateAuth", this.authData);

      this.saveApi.isLoading = false;
      this.$router.push({ name: "PageExploreMenu" });
    };
     this.sellApi.callbackSuccess = () => {
      this.isClickSell = false
      this.sellApi.isLoading = false;
        this.$api.fetch(this.api);
    };
  },
  mounted() {
    this.enterHouse = true;
    this.show = true;
    this.$api.fetch(this.api);
  },
  methods: {
    shop() {
      this.$router.push({
        name: "PageHomeShop",
      });
    },
    exit() {
      this.$router.push({
        name: "PageExploreMenu",
      });
    },
    selectItem(item) {
      if(!this.isClickSell){
      this.isChanged = true;
      if (item.Item.category == "hat") {
        if (this.selectedHat && this.selectedHat.key == item.Item.key) {
          this.selectedHat = null;
        } else {
          this.selectedHat = item.Item;
        }
      } else if (item.Item.category == "back") {
        if (this.selectedBack && this.selectedBack.key == item.Item.key) {
          this.selectedBack = null;
        } else {
          this.selectedBack = item.Item;
        }
      } else if (item.Item.category == "right") {
        if (this.selectedRight && this.selectedRight.key == item.Item.key) {
          this.selectedRight = null;
        } else {
          this.selectedRight = item.Item;
        }
      } else if (item.Item.category == "left") {
        if (this.selectedLeft && this.selectedLeft.key == item.Item.key) {
          this.selectedLeft = null;
        } else {
          this.selectedLeft = item.Item;
        }
      }
      // this.selectedItems.push({category:item.Item.category, imageUrl:JSON.parse(item.Item.images).apparel.url,key:item.Item.key})
      // console.log(this.selectedItems)
      // if (!this.selectedItem.key != item.Item.key) {
      //   this.selectedItem = item.Item;
      //   this.selectedItemInventory = item.Inventory;
      //   var avatar = this.$_.cloneDeep(this.avatar);
      //   avatar[this.selectedItem.category] = this.selectedItem.key;
      //   this.newAvatar = avatar;
      // }
      }
    },
    checkSelected(item) {
      if (this.selectedHat && this.selectedHat.key == item.Item.key) {
        return true;
      } else if (this.selectedLeft && this.selectedLeft.key == item.Item.key) {
        return true;
      } else if (
        this.selectedRight &&
        this.selectedRight.key == item.Item.key
      ) {
        return true;
      } else if (this.selectedBack && this.selectedBack.key == item.Item.key) {
        return true;
      } else {
        return false;
      }
    },
    getAvatar() {
      if (this.avatar.hat) {
        var hat = this.avatar.hat;
        var selectedHat = this.$_.filter(this.itemData, function (o) {
          return o.Item.key == hat;
        });
        this.selectedHat = selectedHat[0].Item;
      }

      if (this.avatar.left) {
        var left = this.avatar.left;
        var selectedLeft = this.$_.filter(this.itemData, function (o) {
          return o.Item.key == left;
        });
        this.selectedLeft = selectedLeft[0].Item;
      }
      if (this.avatar.right) {
        var right = this.avatar.right;
        var selectedRight = this.$_.filter(this.itemData, function (o) {
          return o.Item.key == right;
        });
        this.selectedRight = selectedRight[0].Item;
      }
      if (this.avatar.back) {
        var back = this.avatar.back;
        var selectedBack = this.$_.filter(this.itemData, function (o) {
          return o.Item.key == back;
        });
        this.selectedBack = selectedBack[0].Item;
      }
    },
    saveAvatar() {
      this.avatar.hat = this.selectedHat ? this.selectedHat.key : "";
      this.avatar.back = this.selectedBack ? this.selectedBack.key : "";
      this.avatar.left = this.selectedLeft ? this.selectedLeft.key : "";
      this.avatar.right = this.selectedRight ? this.selectedRight.key : "";
      this.buyConfirmDialog = true;
      this.enterHouse = false;
      // this.saveApi.params = this.$_.clone({
      //   avatar: JSON.stringify(this.avatar),
      // });

      // this.$api.fetch(this.saveApi);
    },
    confirmSave() {
      this.saveApi.params = this.$_.clone({
        avatar: JSON.stringify(this.avatar),
      });

      this.$api.fetch(this.saveApi);
    },
    sell(item){
      this.isClickSell = true
        this.sellApi.params = this.$_.clone({
        itemKey:item.Item.key,
        quantity:1
      });
  this.$api.fetch(this.sellApi);
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.a = this.a + 8;
      this.b = this.b + 8;
    },
    previousPage() {
      this.currentPage = this.currentPage - 1;
      this.a = this.a - 8;
      this.b = this.b - 8;
    },
    startShow() {
      setTimeout(() => {
        this.show = true;
      }, 500);
    },
  },
  beforeMount() {
    if (this.auth.Event) {
      this.authData = this.$_.clone(this.auth);
      this.authData.Session = this.authData.sessionStudent;
      this.$store.commit("updateAuth", this.authData);
    }
  },
};
</script>
  <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

.shop-panel {
  z-index: 1;
  margin: 10px;
  left: 0px;
  position: absolute;
}

.burst {
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/island/Burst.png") center bottom !important;
  background-size: cover !important;
  position: absolute;
  opacity: 0.6;
  z-index: 0;
}
.text-border {
  text-shadow: -1px 1px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
  z-index: 3;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-height: 500px) {
  #home {
    background-size: auto 500px !important; /* Force the image to its minimum width */
  }
}

#home {
  background: url("../../../assets/background/BGHome.png") center top;
  background-size: cover !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#home-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.save-confirm {
  position: fixed;
  background-color: #a9f495;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}
.plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.back-plot {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.low_plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.low_graphics {
  background: none !important;
}

.low_island_plot {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px;
  margin: 0 10px;
  /* padding: 20px !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_title_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  padding: 5px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 20px;
}
.low_island_building_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_coin_plot {
  background-color: rgb(246, 246, 246) !important;
  width: 100%;
  margin-right: 10px;
  padding: 5px 2%;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.low_island_name_plot {
  background-color: rgb(246, 246, 246) !important;
  width: calc(100% - 20px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 0 10px;
  padding: 5px 2%;
  border-radius: 10px;
}

.welcome-enter-active {
  animation: welcome-in 0.5s;
}
.welcome-leave-active {
  animation: welcome-out 0.5s reverse;
}
@keyframes welcome-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes welcome-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.text-stroke-md {
  color: #fff;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.items-panel {
  border: #caad87 5px solid;
  background-color: #f7efe3;
  padding-bottom: 40px !important;
}
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 60px 0 0px 0;
  /* background-color: #a9f495; */
  position: relative;
}

.main-body {
  /* background-color: #a9f495; */
  background-color: rgba(30, 255, 68, 0.3);
}

</style>